import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ForgetPassword from "../pages/auth/ForgetPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import Home from "../pages/home/Home";
import AboutUs from "../pages/aboutus/AboutUs";
import ContactUs from "../pages/contactus/ContactUs";
import HomeHeader from "../components/layout/home/header/HomeHeader";
import HomeFooter from "../components/layout/home/footer/HomeFooter";
import CenterRegister from "../pages/auth/CenterRegister";
import Profile from "../pages/profile/Profile";
import ProtectedRoute from "./ProtectedRoute";
import SearchCenter from "../pages/searchcenter/SearchCenter";
import Donation from "../pages/donation/Donation";
import SpeakerRequest from "../pages/speaker_request/SpeakerRequest";
import Book from "../pages/book/book";
import DonationSucess from "../pages/donation/donationsucess";
import Letter from "../pages/donation/letter";
import PrivacyPolicy from "../pages/PrivacyPolicy/privacypolicy";
import TermOfService from "../pages/Termofconditions/termsofservice";
import Adminpayment from "../pages/donation/adminpayment";
import Information from "../pages/donation/information";
import Churchesabout from "../pages/aboutus/churches";
import HBCU from "../pages/hbcu/hbcu";

const LoginRoutes = () => {
  // const location = useLocation();
  // const noFooterPaths = [
  //   "/login",
  //   "/register",
  //   "/forget-password",
  //   "/reset-password",
  //   "/center-register",
  // ];
  return (
    <>
      <HomeHeader />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/book" element={<Book />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/center-register" element={<CenterRegister />} />
        <Route path="/search-center" element={<SearchCenter />} />
        <Route path="/donation/:id" element={<Donation />} />
        <Route path="/speaker-request" element={<SpeakerRequest />} />
        <Route path="/success" element={<DonationSucess/>}/>
        <Route path="/letter" element={<Letter/>}/>
        <Route path="/privacy" element={<PrivacyPolicy/>}/>
        <Route path="/termofservice" element={<TermOfService/>}/>
        <Route path="/donate-to-us" element={<Adminpayment/>}/>
        <Route path="/information" element={<Information/>}/>
        <Route path="/church" element={<Churchesabout/>}/>
        <Route path="/hbcu" element={<HBCU/>}/>


        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
             </ProtectedRoute>
          }
        />
      </Routes>
      <HomeFooter />
      {/* {!noFooterPaths.includes(location?.pathname) && <HomeFooter />} */}
    </>
  );
};

export default LoginRoutes;
