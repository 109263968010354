import React from "react";
import style from "./aboutus.module.css";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import EmpoweringAbout from "./empowering";
import Championabout from "./champions";

import Book from "./Book";
import Newsletter from "../home/newsletter";

import Supportabout from "./support";
import mike from "../../asserts/mike.png";
import { useEffect } from "react";

const AboutUs = () => {

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <>
      <div className={style.aboutimage}>
        <div className="container">
        

          <div className={style.aboutmainp}>
            <p>
              The decision to abort is not made from a place of empowerment,but
              from a place of fear,desperation.
            </p>
          
          </div>
        </div>
      </div>
      <EmpoweringAbout></EmpoweringAbout>
      <Championabout></Championabout>
 

      <div className={style.aboutus_row}>
        
        <section id="foundersection">
          <div className="container">
            <div className={style.aboutmaincol}>
              <Row className="m-0">
                {/* Image Column */}
                <Col className="text-center text-md-start p-0" lg={4} md={12}>
                  <div className={style.aboutusimagecontainer}>
                    <img src={mike} alt="story" />
                  </div>
                </Col>
                {/* Text Column */}
                <Col className="m-auto p-0" lg={8} md={12}>
                  <div className={style.abouttextcontainer}>
                    <div className={style.aboutus_heading}>About The Founder</div>
                    {/* <div className={` ${style.aboutus_text}`}> */}

                    {/* </div> */}
                    <div className={style.abouthead}>
                   <p>
                   An entrepreneur at heart. A serial entrepreneur. I’ve spent my entire adult life in various business ventures. For the last ten years, I’ve longed for an opportunity to use my many years of hard-earned business knowledge to create an enterprise that would have a positive impact on the lives of others. I believe this pro-life crowdfunding platform will be the instrument that accomplishes that.</p>
                   <p>
                   I’m often asked what motivated me to take on this issue. I can sum it up in one word—injustice. Justice is not just the principal cornerstone of civilized society; it is also the guiding principle for treating others fairly. At its core, it entails treating everyone equally regardless of their background, social status, or personal beliefs. Simply put, justice demands that what you do for one , you do for the other.
                   </p>
                   <p>
                   When I objectively assess the overwhelming support given by the pro-choice community to the abortion-mined female facing an unplanned pregnancy versus the total lack of support offered for the female who’s desperately trying to stave off an abortion, the unfairness of it jumps off the page.
                   </p>

                   <p>
                   For a movement (pro-choice) built on freedom of choice, I think anyone would agree that it’s unjust to devote all their energies to one group of women facing unplanned pregnancy and none to the other. It’s clear to me that the woman whose conscience dictates that she makes a different choice is being ignored. I want to help change that.
                   </p>
                      
                      <h4>Your donation makes a difference</h4>
                      <div className={style.aboutheadbutton}>
                        <a href="/search-center">
                          {" "}
                          <button>Stand With Us</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
        {/* <div className="my-4">
      
          <Row className="">
            <Col className="m-auto" md={6}>
              <div className={style.aboutus_heading}>About the Founder  </div>
              <div className={`p-3 ${style.aboutus_text}`}>
                Mike Goss is a minister, businessman, husband, father, and
                author of The Blueprint for Ending Abortion in the Church.
                Unapologetically pro-life, he’s convinced he’s found a better
                way forward in addressing unplanned pregnancies that empower
                women and couples to choose life for the preborn baby and
                abundant life for their family and it begins with the church.
              </div>
            </Col>
            <Col className="" md={6}>
              <div className=" text-md-end text-center">
                <img className="w-75" src="/aboutus/aboutus5.png" alt="story" />
              </div>
            </Col>
          </Row>
        </div> */}
        {/* <div className="my-4 "> */}
        {/* <div className={style.aboutus_heading}>
            Why I donated. My personal story{" "}
          </div> */}
        {/* <div className="p-3">
            {" "}
            First, let me say upfront that I am by no means wealthy. For me,
            it’s been 40 years of toil with boots on the ground and a hand to
            the plow. You can be sure that for my wife and I, to donate $100,000
            was a significant sacrifice. And you can also be sure that it is one
            of the best we have ever made. I feel honored that God would give me
            the desire and the ability to contribute to many life-affirming
            organizations that help women tilt the scales in favor of life for
            their babies.
            <br />
            One of the compelling reasons that moved me to take this action is
            seeing the massive outpouring of compassion for women who desire an
            abortion while ignoring the women who are struggling to avoid that
            emotional hit. 
            <br />
            Large corporations, celebrities, pro athletes, and it seems like
            anyone who had ten dollars they could afford to put on a credit card
            was pouring millions into organizations like Planned Parenthood,
            whose coffers are already bursting at the seams. It was
            disheartening to see so many with a hyper-focus on helping women who
            desire an abortion and total disregard for those who need help in
            finding alternative solutions. It should be a discouraging signal to
            everyone to know that we’ve devalued human life, where we will
            gladly support women who want the option to take life but are
            unwilling to support women who seek an option to avoid it.
            <br />
            Seeing that compelled me to stand with pregnancy help centers that
            assist women and couples in crisis situations.  And maternity homes
            that offer a safe space for young girls who have been abandoned and
            those who have experienced sex trafficking and are now pregnant and
            traumatized. And mothers who’ve fallen on hard times and are
            homeless, struggling to regain stability for themselves and their
            children. Some of it went to adoption agencies that help make the
            adoption process more affordable for millions of childless couples
            in the United States who want to adopt but can't because of the high
            costs involved.
            <br />
            There’s another reason for my donation that’s deeply personal. I was
            once one of those unborn babies whose life was hanging by a thread.
            At the time of my conception, my mom and dad were not committed to
            each other or fully committed to me. I never knew my birth mother
            and, for decades, didn’t care to know her since she didn’t care to
            know me. Supposedly, she pawned me off on some people with the
            promise to return, but she never did. Sometime later, my dad, still
            single, came to his senses, found me, and brought me to live with
            him. The father is the one who helps turn a crisis pregnancy into a
            celebrated one. Four decades later, after I finally erased the
            emotional debt, I felt she owed, I sought to find her. When I did,
            she was already deceased. Realizing the possibility of ever knowing
            anything about that side of my family was gone, I felt like loose
            change tossed in a sock drawer—no real value. If I had found her,
            though, I already knew what I would say after I gave her a giant
            hug. In so many words, thank you! Thank you for not ending my life.
            Thank you for allowing God a chance to turn a messed-up situation
            into a miracle. Although abortion was not legal in the year I was
            born, it was available. For whatever reason, even under what had to
            be very stressful circumstances, as all women are in that situation,
            she chose to give me life. After I finished, two others would wait
            for their turn: my kids. 
            <br />
            Knowing my little gift enabled at least one more woman to give God a
            chance at turning another ‘mess’ into a miracle, which was one of
            the greatest feelings I’ve ever experienced. Give it a try
            
          </div> */}
        {/* </div> */}

        {/* <div className="my-4">
          <div className={style.aboutus_heading}>What We Do</div>
          <Row className="text-center">
            <Col md={6}>
              <div>
                <img className="" src="/aboutus/support.svg" alt="story" />
              </div>
              <div>Support Network</div>
              <div>
                We connect women with pregnancy centers that offer a range of
                services , from medical care to counselling and material
                assistance.
              </div>
            </Col>
            <Col md={6}>
              <div>
                <img className="" src="/aboutus/advocacy.svg" alt="story" />
              </div>
              <div>Advocacy</div>
              <div className={style.aboutus_text}>
                We raise awareness about the needs and challenges faced by these
                women and advocate for policies that protect and support them.
              </div>
            </Col>
            <Col md={6}>
              <div>
                <img className="" src="/aboutus/education.svg" alt="story" />
              </div>
              <div>Education</div>
              <div className={style.aboutus_text}>
                We provide educational resources to help women make informed
                decisions and understand their options.
              </div>
            </Col>
            <Col md={6}>
              <div>
                <img className="" src="/aboutus/community.svg" alt="story" />
              </div>
              <div>Community Outreach</div>
              <div>
                Through various programs and events, we reach out to women in
                need and offer them a safe and supportive community.
              </div>
            </Col>
          </Row>
        </div> */}
      </div>
      {/* <div className="greenbg">
        <div className="text-center text-white  w-75 m-auto">
          <div className={`pb-3 greenText`}>Join Us</div>
          <div className="pb-3" style={{ color: "#FEEBC3" }}>
            We invite you to join us in our mission. Whether you want to
            volunteer, donate, or simply learn more about our work, your support
            can make a significant impact. Together, we can ensure that no woman
            feels forgotten.
          </div>
          <div className="pt-2">
            <button
              className="greenbtn"
              type="button"
              onClick={() => navigate("/register")}
            >
              Sign Up Now
            </button>
          </div>
        </div>
      </div> */}
      {/* <Aboutuscard></Aboutuscard> */}

     
      <Supportabout></Supportabout>
       
      <div className={style.aboutrow}>
        <div className={style.imagecontainer}>
          <img className="w-100" src="/empower.png"></img>
        </div>
        <div className={style.abouttextheadp}>
          {/* <h1>
          Empowering women who feel pressured into an abortion is true empowerment.
        </h1> */}
          <h1>
            Pro-choice should mean support for women regardless of their choice.
            Support shouldn’t be given only when the ‘choice’ is to end life.
          </h1>
        </div>
      </div>


      <div className={style.helpabout}>
        <h1>24 Hour Hotline:</h1>
        <h1>(800) 712-HELP (4357)</h1>
      </div>

      <Book></Book>
      <Newsletter></Newsletter>
    </>
  );
};

export default AboutUs;
