import React, { useEffect, useState } from "react";
import style from "./home.module.css";
import VideoPlayer from "../../modals/videoPlayer/VideoPlayer";
import { Row, Col } from "react-bootstrap";

import CenterList from "./CenterList";
import { Link } from "react-router-dom";
import { getAllCenters } from "../../apis/CenterApis";
import { useDispatch } from "react-redux";
import useGetUser from "../../customHook/useGetUser";

import Action from "./Action";
import CardPage from "./Cardpage";
import Mission from "./Mission";

import Movement from "./Movement";
import Newsletter from "./newsletter";
import Neighbour from "./neighbour";

const Home = () => {
  // const dummylist = centerList;
  useGetUser();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [centerList, setCenterList] = useState();
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  useEffect(() => {
    dispatch(getAllCenters({ page: 1, limit: 9 })).then((res) => {
      if (res?.centersList) {
        setCenterList(res?.centersList);
      }
    });
  }, []);
  // const handleClick = () => {
  //   // navigate("/search-center")
  //   navigate("/aboutus/");
  // };

  // const handlePlay = (url) => {
  //   setVideoUrl(url);
  //   setShowVideoPlayer(true);
  // };
  return (
    <>
      {/* <div className={style.dashboard_main}>
        <div className={style.dashboard_row}>
          <Row className="m-0 p-0">
            <Col md={6} className="m-auto">
              <div className="mt-3">
                <div className={`${style.bannerHeading}`}>
                  Welcome to the I Stand With The Forgotten Women
                </div>
                <div className="pt-4" style={{ fontSize: "20px" }}>
                  Empowering women facing unplanned pregnancies. Join us in
                  rewriting stories of hope. Explore, donate, and make a
                  difference today.
                </div>
                <div className="pt-4">
                  <button className={`greenbtn`} type="button" onClick={handleClick}>
                    Choose a center to support
                  </button>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="my-5">
                <img
                  className="w-100 mt-5 mb-5"
                  src="/dashboard/banner.svg"
                  alt="banner"
                />
              </div>
              <div className="text-center mb-5">
               
                <button
                  className={`bluebtn mb-5 ${style.yelbtn}`}
                  type="button"
                  onClick={handleClick} 
                >
                  Donate Now
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div> */}
      <div className={`${style.videoContainer}`}>
        <video
          className="w-100"
          src="/Header.mov"
          alt="banner"
          autoPlay
          muted
          loop
        />
        <div className={`${style.overlayContent} container `}>
          <div className={style.contentvideo}>
            <h1>
              Providing alternatives for those whose conscience dictates a
              different choice
            </h1>
            <h2>
              Their Lives Matter, <span>too!</span>
            </h2>
            {/* <a href="https://supportafterabortion.com/resources/unraveled-roots/" target="_blank">READ MORE ABOUT OUR SOCIAL  SHOULD BE</a> */}

            <p className={style.contentPara}>
              Abortion rights are our country’s most divisive societal issue
              since the abolitionists took up the cause to abolish slavery.
              President Lincoln understood then what we must understand now. Our
              nation will be unable to uphold its preeminent position among the
              global powers if we continue to hand this longstanding culture war
              off to future generations to fight. Whether you’re pro-life or
              pro-choice, we owe it to them to seek common ground. The common
              ground we all should be able to stand on is women should be
              presented with alternatives to abortion and with the
              same determination that abortion is presented. We should all be
              able to agree that reducing the number of abortions is in the best
              interest of everyone. Not only for the sake of our unborn but for
              the well-being of the mothers who carry them, the fathers who
              conceived them, and for the sake of our humanity..
            </p>
            <div className={style.homesocialicons}>
          <Link to="https://www.facebook.com/share/hUuQTv9TUqkxVtBM/?mibextid=LQQJ4d" className={style.footer_links} target="_blank">  <img src="/fbsvg.svg"></img></Link><br />
          {/* <Link to="#" className={style.footer_links}>Twitter</Link><br /> */}
          <Link to="https://www.instagram.com/istandwithforgottenwomen/"className={style.footer_linksinsta} target="_blank">  <img src="/instasvg.svg"></img></Link><br />

          <Link to="https://x.com/4gottenwomen"className={style.footer_links} target="_blank">  <img src="/twitternew.png"></img></Link><br />
          </div>
            <div className={`${style.donatebutton} mb-5`}>
              <a href="/donate-to-us/">
                <button>Stand With Us</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={style.abort_main}>
        {/* <div className={`text-black ${style.dashboard_row}`}> */}
        <Row style={{ marginRight: "0px" }}>
          {/* <div className="d-flex "> */}
          <Col md={6} className={style.abortimg}>
            <img
              src="/forgotten.png"
              // playing={false}
              // loop={true}
              // controls={false}
              // muted={true}
              // width="100%"
              // height="100%"
              // playsinline={true}
              style={{
                width: "90%",
                // height: "100%",
                // objectFit: "cover", // Ensures the image covers the container without distortion
                // position: "relative",
                // zIndex: 1,
                // position: "relative",
                // zIndex: 1,
                // background: "black",
              }}
            />
            {/* <img
                  src="/playicon.svg"
                    className="position-absolute top-50 start-50 translate-middle"
                    style={{
                      fontSize: "3rem",
                      color: "white",
                      cursor: "pointer",
                      zIndex: 2,
                     
                    }}
                    // onClick={() =>
                    //   handlePlay(
                    //     "/video.png"
                    //   )
                    // }
                  /> */}

            {/* <video className="mt-4" controls>
                    <source
                      src="https://www.pexels.com/video/light-field-summer-sun-27270701/"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video> */}
            {/* <img
                  className="mt-4"
                  src="/dashboard/abortBanner.svg"
                  alt="banner"
                /> */}

            {/* <div
                  className="pt-3"
                  style={{ fontSize: "20px", color: "#484848" }}
                >
                  Free with donation of any amount
                </div> */}

            <VideoPlayer
              showVideoPlayer={showVideoPlayer}
              setShowVideoPlayer={setShowVideoPlayer}
              videoUrl={videoUrl}
            />
          </Col>
          <Col md={6} className={style.bandata}>
            <div className={style.bancol}>
              <h1 className={style.bannerHeading3}>
                Who Are the Forgotten Women?
              </h1>

              <p className={style.bannerHeading2}>
                Countless women have terminated their pregnancies due to what
                they perceived as insurmountable obstacles that left them
                essentially without a 'choice' and many others are left struggling with the psychological consequences. 
                These are the forgotten women whose plight has been omitted from the public discourse on
                abortion rights and slipped through the cracks of our
                compassion.
              </p>
              <p className={style.bannerHeading2}>
                These women need and deserve our support.
                <p>
                  <span> Their Lives Matter, too!</span>
                </p>
              </p>
              <div className={style.bannerbutton}>
                <a href="/donate-to-us/">
                  <button>Stand With Us</button>
                </a>
              </div>
            </div>
            {/* <div
                  className="pt-4"
                  style={{ fontSize: "20px", color: "#484848" }}
                >
                  Buy your copy now and help bring their stories to light.
                </div> */}
            {/* <div className="py-4">
                  <button className={`yellowbtn  ${style.yelbtn}`} type="button">
                    Buy Now
                  </button>
                </div> */}
          </Col>
        </Row>

        {/* </div> */}
      </div>

      <Action />
      <Neighbour></Neighbour>
      <Mission></Mission>
      <CardPage />
      <div className={style.standcontainer}>
        <img className="w-100" src="/Still.svg" alt="banner" />
        <div className={style.standtext}>
          <h1>I Stand With Them</h1>
          {/* <h2>Mike Goss</h2> */}
          {/* <p>Mike Goss</p> */}
          <a href="/letter/">
            {" "}
            <p>Letter from the Founder</p>{" "}
          </a>
        </div>
      </div>
      {/* <Impact></Impact> */}

      <CenterList centerList={centerList} showfilter={false} />
      {/* <Book></Book> */}
      <Movement></Movement>
      {/* <Data></Data> */}
      <Newsletter></Newsletter>

      {/* <div className="" style={{ backgroundColor: "#06A1D7" }}>
        <div className={style.dashboard_row}>
          <div className={style.centerBox}>
            <div className={style.centerHeading} >
          Find a Pregnancy Center Near You
          </div>
          <div className="my-4">
          <InputGroup className={style.serchInput}>
        <Form.Control
        className={style.serchInputsec}
          placeholder="Enter state or country"
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
        />
        <Button className={style.serchInputbtn} variant="dark" id="button-addon2">
          Search
        </Button>
      </InputGroup>
          </div>
            <Row className="m-0 p-0">
              {dummylist.map((data, index) => (
                <Col className="p-3 " key={index} md={4}>
                  <div className={`${style.centerCard}`}>
                    <div className="position-relative image-container" >
                      <img className="w-100 " src={data.image} alt="center" />
                      <div class="overlay"></div>
                      <AiOutlinePlayCircle
                        className="position-absolute top-50 start-50 translate-middle"
                        style={{ fontSize: "3rem", color: "white" }}
                      />
                    </div>
                    <div className="my-2" style={{ fontSize: "20px" }}>
                      {data.title}
                    </div>
                    <div style={{color:"#454545"}} >{data.description}</div>
                    <div className="my-3  d-flex">
                <div className={style.lightbg}>{data.purpose1}</div> <div className={style.lightbg}>{data.purpose2}</div>
              </div>
                    <div className="d-flex mb-2 align-items-end">
                <div className="flex-fill">
                  ${data.amount} to go
                  <ProgressBar className={style.undertext} now={50} />
                </div>
                <div className="flex-fill text-end">
                  <button className="yellowbtn px-3" >Donate Now </button>
                </div>

              </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
        <div className="greenbg">
        <div className="text-center">
          <div className={`pb-3 greenText`} >Register for Pregnancy Center</div>
          <div className="pt-2 ">
            <button className="greenbtn" type="button">Register Your Center</button>
          </div>
        </div>
        </div>
      </div> */}
    </>
  );
};

export default Home;
