import React from "react";
import style from "./homefooter.module.css";
import { Row, Col } from "react-bootstrap";

import { Link} from "react-router-dom";

const HomeFooter = () => {

  return (
 
    <div className={style.footer_main}>
       {/* <div className="text-start mb-3 ms-2">
        <img
          className={`${style.footer_logo} `}
          src="/mainlogo.svg"
          alt="logo"
        />
      </div> */}
      <div className={style.bigscreenfooter}>
      <div className="container">
    
      <Row className="m-0 p-0">

      <Col md={6} lg={4} >
          <img
            className={`${style.footer_logo}`}
            src="/mainlogo.svg"
            alt="logo"
          />
           <h6 className={style.footer_heading}>FOLLOW US</h6>
           <div className="d-flex">
            <Link to="https://www.facebook.com/share/hUuQTv9TUqkxVtBM/?mibextid=LQQJ4d" className={style.footer_links} target="_blank"><img src="/fbsvg.svg"/></Link><br />
            {/* <Link to="#" className={style.footer_links}>Twitter</Link><br /> */}
            <Link to="https://www.instagram.com/istandwithforgottenwomen/" className={style.footer_linksinsta} target="_blank">  <img src="/instasvg.svg"></img></Link><br />
            <Link to="https://x.com/4gottenwomen" className={style.footer_linksinsta} target="_blank">  <img src="/twitternew.png"></img></Link><br />
            </div>
            {/* <Link to="#" className={style.footer_links}>Medium</Link><br /> */}
        </Col>
        {/* <Col md={2} xs={12} className="text-center mb-3 mb-md-0">
          <h6 className={style.footer_heading}>Location & Contact</h6>
          <p className={style.para}>Mailing Address 4771 Sweetwater, PMB 107 Sugar Land, TX 77497</p>
          <p className={style.para}>Physical Address 226 Lakeview Drive,  Sugar Land, TX 77498</p>
        </Col> */}
        <Col md={2} xs={12} className="text-start mb-3 mb-md-0">
          <h6 className={style.footer_heading}>Learn More</h6>
          <Link to="https://blog.istandwiththeforgottenwomen.org/" className={style.footer_links}>Blog </Link><br/>
          <Link to="/contactus/" className={style.footer_links}>Contact Us </Link><br/>
          {/* <Link to="/privacy/" className={style.footer_links}>Privacy Policy </Link><br/>
        <Link to="/termofservice/" className={style.footer_links}>Terms of services </Link><br/> */}
        <Link to="/speaker-request/" className={style.footer_links}>SpeakerRequest </Link><br/>
        <Link to="https://store.care-net.org/making-life-disciples/" target="_blank" className={style.footer_links}>Life Disciples </Link><br/>
        {/* <a href="#coresection" className={style.footer_links}>Beliefs </a><br/>
        <Link to="/speaker-request/" className={style.footer_links}>How We Do It </Link><br/>
        <Link to="/speaker-request/" className={style.footer_links}>About the founder </Link><br/> */}
      

        </Col>
        <Col md={2} xs={12} className="text-start text-md-start mb-3 mb-md-0">
       <a href="/book/"> <img  className="w-100" src="/abortionbook.png"></img></a> 
  
         
        </Col>
      
        <Col md={2} className="d-flex justify-content-center">
      
        <a href="https://9eb34e-77.myshopify.com/collections/all" target="_blank"> <img  className={style.newbook} src="/booknew.png"></img></a> 
        </Col>
        <Col md={2} xs={8} className="text-center text-md-end mt-2">
       <p className={style.footerpara}>Their</p>
       <p className={style.footerpara}>Lives</p>
       <p className={style.footerpara}>Matter</p>
       <p className={style.footerparato}>too!</p>
        </Col>
        <div className={style.footertag}>
       <p >© 2024 I Stand With The Forgotten Women. All rights reserved.</p>
       {/* <p className={style.footertagp}>Made with love by LV Branding</p> */}
        </div>
  
  
        {/* <Col className="p-0" md={2}>
          <div className="d-flex justify-content-around mb-3">
            <FaFacebook className={style.socialmedia} />
            <FaLinkedin className={style.socialmedia} />
            <FaYoutube className={style.socialmedia} />
            <FaInstagram className={style.socialmedia} />
          </div>
        </Col>
        */}
        
    
        {/* <Col md={10} className="text-end">
          <span className={style.footer_link} onClick={()=>navigate("/speaker-request")}> Speaker Request</span> | 
          Privacy
          Policy | Terms of Service | Donation Refund Policy | Copyright © 2024
        </Col> */}
      </Row>
      </div>

      </div>
     <div className={style.smallscreenfooter}>

      <div className="container">
    
    <Row className="m-0 p-0">

    <Col md={6} >
        <img
          className={`${style.footer_logo}`}
          src="/mainlogo.svg"
          alt="logo"
        />
         <p className={style.footerpara}>Their Lives  Matter,<span> too! </span></p>
        
      </Col>
   
      <Col md={6} className="text-start mb-3 mb-md-0">
      <div  className={style.smallscreenfootheading}>
      <div  className={style.smallscreenfoothead}>
        <h6 className={style.footer_heading}>Learn More</h6>
        <Link to="https://blog.istandwiththeforgottenwomen.org/"  className={style.footer_links}> Blog </Link><br/>
        <Link to="/contactus/" className={style.footer_links}>Contact Us </Link><br/>
        {/* <Link to="/privacy/" className={style.footer_links}>Privacy Policy </Link><br/>
        <Link to="/termofservice/" className={style.footer_links}>Terms of services</Link><br/> */}
        <Link to="/speaker-request/" className={style.footer_links}>SpeakerRequest </Link><br/>
        <Link to="https://store.care-net.org/making-life-disciples/" target="_blank" className={style.footer_links}>Life Disciples </Link><br/>
        {/* <Link to="#coresection" className={style.footer_links}>Beliefs </Link><br/>
        <Link to="/speaker-request/" className={style.footer_links}>How We Do It </Link><br/>
        <Link to="/speaker-request/" className={style.footer_links}>About the founder </Link><br/> */}

        </div>
        <div  className={style.smallscreenfoothead}>
        <h6 className={style.footer_heading}>FOLLOW US</h6>
        <div className="d-flex">
          <Link to="https://www.facebook.com/share/hUuQTv9TUqkxVtBM/?mibextid=LQQJ4d" className={style.footer_links} target="_blank">  <img src="/fbsvg.svg"></img></Link><br />
          {/* <Link to="#" className={style.footer_links}>Twitter</Link><br /> */}
          <Link to="https://x.com/4gottenwomen" className={style.footer_linksinsta} target="_blank">  <img src="/twitternew.png"></img></Link><br />
          <Link to="https://www.instagram.com/istandwithforgottenwomen/"className={style.footer_linksinsta} target="_blank">  <img src="/instasvg.svg"></img></Link><br />
          </div>

          {/* <Link to="#" className={style.footer_links}>Medium</Link><br /> */}
          </div>
          </div>
      </Col>
    
      <div className={style.footertag}>
     <p >© 2030 I Stand With The Forgotten Women. All rights reserved.</p>
     {/* <p className={style.footertagp}>Made with love by LV Branding</p> */}
      </div>

    </Row>
    </div>
    </div>
    </div>
  );
};

export default HomeFooter;
