import React from "react";
import style from "./book.module.css";
import { Col, Row } from "react-bootstrap";

const LifeApprochNeeded = () => {
  return (
    <div className={style.lifapprochneeded_wrapper}>

      <div className="container">
        {/* <div className={style.aboutmaincol}> */}
        
            <Row>
              <Col className={`${style.life_approach_mike} text-center text-md-start`} lg={5} md={12} sm={12}>
              <div className={style.mainlife}>
                <div className={style.left_banner_section}>
                  <img className="" src="/book/mike_goss.png" alt="story" />
                </div>
                <div className={`${style.life_approach_left_p}`}>
                  <p> <span className={style.mikename}>Mike Goss, </span> Unapologetically pro-life, with a mind for business and a heart for Christ, he feels privileged to use his business experience to create the first-ever crowdfunding platform to raise money for life-affirming organizations. He is confident he’s discovered a better way of addressing unplanned pregnancies. One that will empower women to choose life for their babies and abundant life for their families—that begins with the church. </p>
                  {/* <p>It’s one that he says will empower women to choose life for their babies and abundant life for their families— and it begins with the church.</p> */}
                </div>
                </div> 
              </Col>
              <Col className={style.right_section_life_approach} lg={7} md={12} sm={12}>
                <div className={`${style.life_approach_heading}`}>The pro-life focus has proven to save lives, but only a pro-abundant life focus can transform lives.</div>
                <div className={` ${style.life_approach_p}`}>
                  <p>As God-honoring as our Pro-Life mission has been, we've been unable to move the needle in breaking the intergenerational cycle of unplanned pregnancies that leads to the risk of abortion.</p>

          <p>In John 10:10, we see Christ making it clear he wasn't only pro-life but pro-abundant life. This means we must enlarge our mission and become pro-abundant life as he was. We're still saving for heartbeats. The only difference now is it's with a heaven-bound purpose. </p>
                  
                </div>
                <div className={`${style.life_approach_heading}`}>Pro abundant life approach is our way forward</div>
                </Col>
           
          {/* <Col className={style.right_section_life_approach} lg={6} md={6} sm={12} xs={12}> */}

{/* <p className={` ${style.life_approach_prolife}`}></p> */}
        
            {/* <div className={`${style.life_approach_p}`}>
              <p>Abortion is one of the most divisive issues of our time, presenting profound ethical, moral, and societal implications. This issue demands pastoral leadership now more than ever. Until today, there hasn’t been a comprehensive resource to guide pastors in addressing a problem that has touched virtually every pew in our congregations.<span>Our decades-long silence has allowed our Adversary free rein to evangelize virtually the entire Body of Christ with false doctrine.</span> </p>
            </div> */}
          {/* </Col> */}

        </Row>
        {/* </div> */}
      </div>
    </div>
  );
};

export default LifeApprochNeeded;
