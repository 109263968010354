import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import style from "./home.module.css";

const Action = () => {
  return (
    <div className={style.imageContainer}>
      {/* <img className='w-100' src="/female.png" alt="banner" /> */}
      <div className={style.content}>
     <div className="container">
       <Row>
       <div className={style.actionAndCardsContainer}>
        <Col xs={12} lg={5} md={6}>
          <div className={style.textSection}>
            <h1>
            Thinking that  <br />
            created these <br />
            problems will <br></br>
            not be the<br></br>
            thinking that<br></br>
            will solve them.
              </h1>
           
{/* 
          <div className={style.actionbutton}>
            <a href="/login/"><button>Join Us</button></a>
          </div> */}
        
        </div>
        </Col>
       
      
        <Col xs={12} lg={7} md={6}>
        <div className={style.cardblock}>
        <div className={style.cardsContainer}>
            <Card className={style.customCards}>
              <Card.Body className="p-0">
                <div className={style.cardContent}>
                  <div className={style.textContent}>
                    <Card.Title className={style.cardtitle}>2 million</Card.Title>
                    <Card.Text className={style.cardtext}>
                    Abortions occur worldwide each year, according to the World Health Organization (WHO).
                    </Card.Text>
                  </div>
                  <div className={style.socialIcons}>
                  <a href="https://x.com/4gottenwomen" target="_blank"><img src="/twitter32.png"></img></a> 
                  <a href="https://www.facebook.com/share/hUuQTv9TUqkxVtBM/?mibextid=LQQJ4d" target="_blank">   <img src="/facebook.svg"></img></a>

                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card className={style.customCards2}>
              <Card.Body className="p-0" >
                <div className={style.cardContent}>
                  <div className={style.textContent}>
                    <Card.Title className={style.cardtitle}>Alarming</Card.Title>
                    <div className={style.actioncardmain}>
                    <div className={style.actioncardtext}>
                      <span className={style.cardtitlehead}>60%</span>
                    <Card.Text className={style.acardtext}>
                        Reported feelings of isolation and alienation
                    </Card.Text>
                    </div>
                    <div className={style.actioncardtext}>
                    <span className={style.cardtitlehead}>38%</span>
                    <Card.Text className={style.acardtext}>
                        Reported problems with anxiety, depression, drug abuse and / or suicidal thoughts.
                    </Card.Text>
                   
                    </div>
                    </div>
                  
                  </div>
                  <div className={style.socialIcons}>
                  <a href="https://x.com/4gottenwomen" target="_blank"><img src="/twitter32.png"></img></a> 
                  <a href="https://www.facebook.com/share/hUuQTv9TUqkxVtBM/?mibextid=LQQJ4d" target="_blank">   <img src="/facebook.svg"></img></a>


                  </div>
                </div>
                <a href="https://lozierinstitute.org/category/abortion-fetal-development/" target="_blank" className={style.actiona}>
                    (https://lozierinstitute.org/category/abortion-fetal-development/)
                    </a>
              </Card.Body>
            </Card>
            <Card className={style.customCards}>
              <Card.Body className="p-0">
                <div className={style.cardContent}>
                  <div className={style.textContent}>
                    <Card.Title className={style.cardtitle}>In 2021</Card.Title>
                    <Card.Text className={style.cardtext}>
                    According to the Lozier Institute study, 65% felt they were forced into it.
                   {/* In the year before Roe V. Wade was overturned, 900,000 abortions, 60% wanted an alternative  */}
                   {/* <div className={style.singletext}> 
                    
                    (Lozier Institute) </div> */}
                    </Card.Text>
                  </div>
                  <div className={style.socialIcons}>
                  <a href="https://x.com/4gottenwomen" target="_blank"><img src="/twitter32.png"></img></a>                   
                  <a href="https://www.facebook.com/share/hUuQTv9TUqkxVtBM/?mibextid=LQQJ4d" target="_blank">   <img src="/facebook.svg"></img></a>

                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card className={style.customCards}>
              <Card.Body className="p-0">
                <div className={style.cardContent}>
                  <div className={style.textContent}>
                    <Card.Title className={style.cardtitle2}>Abortion Pill Regret</Card.Title>
                    <Card.Text className={style.cardtext}>
                    77% regret taking the abortion pill 
                   {/* On the abortion pill shows 77% of women regretted their abortions in 2017 */}
                    </Card.Text>
                  </div>
                  <div className={style.socialIcons}>
                  <a href="https://x.com/4gottenwomen" target="_blank"><img src="/twitter32.png"></img></a> 
                  <a href="https://www.facebook.com/share/hUuQTv9TUqkxVtBM/?mibextid=LQQJ4d" target="_blank">   <img src="/facebook.svg"></img></a>


                  </div>
                </div>
                <a className={style.actiona4}>https://doi.org/10.1080.4/10410236.202.177050</a>
              </Card.Body>
            </Card>
            </div>
            </div>
            </Col>
   
            </div>
        </Row>
        </div>
      </div>
      </div>
  
  );
};

export default Action;
