import React, { useEffect, useState } from "react";
import style from "./donation.module.css";
import {
 
  Col,
  Form,

  ProgressBar,
  Row,

} from "react-bootstrap";
import { AiOutlinePlayCircle } from "react-icons/ai";

import ReactPlayer from "react-player/lazy";

import {

  useLocation,
  // useNavigate,
  useSearchParams,
} from "react-router-dom";
import VideoPlayer from "../../modals/videoPlayer/VideoPlayer";
// import { Country, State } from "country-state-city";
import { fileBaseUrl } from "../../utils/api";
import {
  oneTimePayment,
  createSubscription,
  getRecentTransaction,
} from "../../apis/paymentApis";
import { useDispatch } from "react-redux";
import { getToken } from "../../utils/api";
import { toast } from "react-toastify";
import TransactionStatus from "../../modals/transaction/TransactionStatu";
import { getCenterById } from "../../apis/CenterApis";
import * as yup from "yup";
import {
  getTotalTransactionandPercentage,
  fetchConnectedAccount,
} from "../../apis/paymentApis";
import useGetUser from "../../customHook/useGetUser";
import { jwtDecode } from "jwt-decode";
const Donation = () => {
  const token = getToken();
  const [searchParams] = useSearchParams();
  useGetUser();
  // const [radioValue, setRadioValue] = useState("1");
  const location = useLocation();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [centerData, setCenterData] = useState(null);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [isdata, setIsData] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [showTextArea, setShowTextArea] = useState(false);
  // const [amount, setAmount] = useState();
  const [showTransactionStatus, setShowTransactionStaus] = useState(false);
  const [donationList, setDonationList] = useState(null);
  const [centerDetail, setCenterDetail] = useState();
  const [usercomment, setUserComment] = useState("");
  const [email, setEmail] = useState("");

  const schema = yup.object().shape({
    amount: yup
      .number()
      .typeError("Amount must be a number")
      .positive("Amount must be a positive number")
      .required("Amount is required"),
  });

  const fetchRecentTransaction = (id) => {
    if (id) {
      dispatch(getRecentTransaction({ id })).then((res) => {
        if (res?.transactions) setDonationList(res.transactions);
      });
    }
  };

  useEffect(() => {
    const success = searchParams.get("success");
    if (success === "true") {
      setShowTransactionStaus(true);
    }
  }, [searchParams]);

  const filesData = (value) => {
    const fileExtension = value?.media?.split(".").pop().toLowerCase();
    const imageExtensions = ["jpg", "jpeg", "png", "gif"];
    const videoExtensions = ["mp4", "avi", "mov", "mkv"];
    const youtubePattern =
      /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    console.log("value", value?.media);
    if (imageExtensions.includes(fileExtension)) {
      value.type = "image";
    } else if (videoExtensions.includes(fileExtension)) {
      value.type = "video";
    } else if (youtubePattern.test(value.media)) {
      console.log("valueenter");
      value.type = "youtube";
    } else {
      value.type = "unsupported";
    }

    return value;

    // return addfileType;
  };

  // let id = localStorage.getItem("centerId");

  // Extracting the ID manually from the pathname
  const path = location.pathname;
  const id = path.split("/").pop();
  console.log("Extracted ID:", id);

  useEffect(() => {
    if (location.state) {
      console.log("Enterlocationstate")
      fetchRecentTransaction(id);
      setCenterData(location.state);
    }
    if (!location.state) {
      console.log("not enetr locationstate")
      dispatch(getCenterById({ id })).then((res) => {
        console.log("entergetcenterbyid",res?.centerData);
        if (res?.centerData) {
          console.log("Entercenter")
          fetchRecentTransaction(id);
          setCenterData(filesData(res?.centerData));
          console.log("Center",centerData)
        }
      });
    }
    dispatch(getTotalTransactionandPercentage({ id: id })).then((res) => {
      if (res?.result) {
        setCenterDetail(res?.result);
        console.log("centerdetial", centerDetail);
        // setTotaDonation(res?.result?.totalAmount)
      }
    });
    setIsData(true);
  }, [location]);

  // const radios = [
  //   { name: "One-time", value: "1" },

  //   { name: "Monthly", value: "month" },
  // ];

  useEffect(() => {
    const fetchConnect = async () => {
      try {
        const accountData = await fetchConnectedAccount({ id: id });
        console.log("Account", accountData);
        setConnectedAccount(accountData);
      } catch (err) {
        console.error("Error fetching connected account:", err);
      }
    };

    if (id) {
      fetchConnect();
    }
  }, [id]);
  const handleDateConversion = (dateTimeString) => {
    const dateObject = new Date(dateTimeString);
    const formattedDateTime = dateObject.toLocaleDateString();
    return formattedDateTime;
  };
  const handlePlay = (url) => {
    setVideoUrl(url);
    setShowVideoPlayer(true);
  };
  const [amount, setSelectedAmount] = useState(""); // State for the selected amount radio button
  const [selectedFrequency, setSelectedFrequency] = useState("month"); // State for the selected frequency radio button
  const [connectedAccount, setConnectedAccount] = useState(null);
  const [Id, setId] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Existing functions...
  const [showInput, setShowInput] = useState(false);

  const handleLinkClick = (e) => {
    e.preventDefault();
    setShowInput((prevShowInput) => !prevShowInput);
  };

  const handleAmountChange = (e) => {
    setSelectedAmount(e.target.value); // Update the selected amount
  };

  const handleFrequencyChange = (e) => {
    setSelectedFrequency(e.target.value); // Update the selected frequency
  };

  useEffect(()=>{
    if (token) {
      console.log("Entertoken");
      const decoded = jwtDecode(token);
      console.log("decoded", decoded);
      setId(decoded?.sub);
      setEmail(decoded?.email);
      setIsLoggedIn(true);
    }
  },[])
  
  const handleDonate = () => {
if(!amount){
  toast.error("Please select a  amount for proceed the payment"); 
  return;
}
if(!email){
  toast.error("Please enter the email address"); 
  return;
}

    // if (!token) {
    //   toast.error("Please LogIn First");
    //   navigate("/login");
    //   return;
    // }
    if (!isNaN(amount) && Number(amount) >= 0) {
      console.log("enter");
      if (selectedFrequency === "1") {
        console.log("ente rinto")
        dispatch(
          oneTimePayment({
            amount: amount,
            centerId: centerData?._id ? centerData._id : centerData.id,
            usercomment: usercomment,
            email: email,
            _id:Id,
          })
        ).then((res) => {
          if (res?.data) {
            window.location.href = res?.data;
            // window.open(res?.data, "_blank");
          }
        });
      } else {
        dispatch(
          createSubscription({
            amount: amount,
            interval: "month",
            centerId: centerData?._id ? centerData._id : centerData.id,
            usercomment: usercomment,
            email: email,
            _id:Id,
          })
        ).then((res) => {
          if (res?.url) {
            window.location.href = res?.url;
            // window.open(res?.url, "_blank");
          }
        });
      }
    } else {
      toast.error("Invalid amount. Please enter a valid number.");
      return;
    }
  };

  if(!isLoggedIn){
    localStorage.setItem("email",email)
  }
  console.log("Comment", usercomment);

  return (
    <div className={style.mainsection}>
      <div className={style.donationcontainer}>
        <div className="container">
          {/* <Row className=" m-0 p-0 ">
          <Col className="" md={6}>
            <div className="mt-5 p-3">
              <div className={`${style.centername}`}>
                {centerData?.centerName}
              </div>
              <div className={style.centerLocation}>
                {centerData?.address}, {centerData?.city} {centerData?.state}{" "}
                {centerData?.country}
                {/* {
                  State.getStateByCodeAndCountry(centerData?.state, centerData?.country)
                    ?.name
                }
                , {Country.getCountryByCode(centerData?.country)?.name} */}
          {/* </div>
            </div>
          </Col> */}

          {/* </Row> */}
          <div className={style.maindonation}>
            <Row>
              <Col md={12} xl={5} lg={12}>
                <div>
                  <div className={`${style.centername}`}>
                    {centerData?.centerName}
                  </div>
                  <div className={style.centerLocation}>
                    {centerData?.address}, {centerData?.city}{" "}
                    {centerData?.state} {centerData?.country}
                    {/* {
                  State.getStateByCodeAndCountry(centerData?.state, centerData?.country)
                    ?.name
                }
                , {Country.getCountryByCode(centerData?.country)?.name} */}
                  </div>
                </div>
                <div className={`p-3`}>
                  <div className="position-relative image-container ">
                    {centerData?.type === "unsupported" && (
                      <img
                        className="w-100 "
                        src={`/defaultCenter.png`}
                        alt="center"
                      />
                    )}
                    {centerData?.type === "image" && (
                      <img
                        className="w-100 "
                        src={`${fileBaseUrl}${centerData?.media}`}
                        alt="center"
                      />
                    )}
                    {centerData?.type === "video" && (
                      <ReactPlayer
                        url={`${fileBaseUrl}${centerData?.media}`}
                        playing={false}
                        loop={true}
                        controls={false}
                        muted={true}
                        width="100%"
                        height="100%"
                        playsinline={true}
                      />
                    )}
                    {centerData?.type === "youtube" && (
                      <ReactPlayer
                        url={centerData.media} // Assuming data.media contains the YouTube URL
                        playing={false}
                        loop={true}
                        controls={true} // You might want controls for YouTube
                        muted={false} // You can decide if you want it muted
                        width="100%"
                        height="157px"
                        playsinline={true}
                        className={style.youtubevideo}
                      />
                    )}
                    <div
                      class="overlay"
                      onClick={() =>
                        centerData?.type === "video" ||
                        (centerData?.type === "youtube" &&
                          handlePlay(centerData?.media))
                      }></div>
                    {centerData?.type === "video" && (
                      <AiOutlinePlayCircle
                        className="position-absolute top-50 start-50 translate-middle overlayIcon"
                        style={{ fontSize: "3rem", color: "white" }}
                        onClick={() => handlePlay(centerData?.media)}
                      />
                    )}
                  </div>
                  {/* <div
                  className="my-2"
                  style={{ fontSize: "26px", textTransform: "capitalize", color:"#ffffff" }}
                >
                  {centerData?.centerName}
                </div> */}
                  <div style={{ color: "#ffffff", marginTop: "15px" }}>
                    {centerData?.description}
                  </div>

                  <div className="mt-5 ">
                    <div
                      className="my-2"
                      style={{ fontSize: "20px", color: "#ffffff" }}>
                      ${centerDetail?.totalAmount} USD raised of $
                      {centerDetail?.goalAmount?.toLocaleString()}
                    </div>
                    <ProgressBar
                      variant="info"
                      animated
                      now={centerDetail?.percentage}
                    />
                    <div
                      className="my-2"
                      style={{ fontSize: "20px", color: "#ffffff" }}>
                      Recent Donations
                    </div>
                    <div className={style.donationtable}>
                      <table className="table m-0">
                        <thead className="thead-dark border-top border-bottom">
                          <tr>
                            <th scope="col">DATE</th>
                            {/* <th scope="col">DONOR</th> */}
                            <th scope="col">AMOUNT</th>
                          </tr>
                        </thead>
                        <tbody className={style.noborder}>
                          {donationList?.map((item, index) => (
                            <tr key={index} className="border-none">
                              <td>{handleDateConversion(item.createdAt)}</td>
                              {/* <td style={{ textTransform: "capitalize" }}>
                                {item.fullName}{" "}
                              </td> */}
                              <td style={{ color: "#06A1D7" }}>
                              ${item.amount} 
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {!donationList && (
                      <div className=" p-5 text-center">
                        <h5> No Data Found </h5>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              {connectedAccount && (
                <Col md={12} xl={7} lg={12}>
                  <div className="p-3">
                    <div className={style.paymentForm}>
                      <div className={style.paymentHead}>
                        Choose A Donation Amount
                      </div>
                      <div className={style.donationpayment}>
                        <div className={style.paymentcontainer}>
                          <Row>
                            <Col md={6}>

                            <div
                              className={
                                amount === "15"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="15"
                                      checked={amount === "15"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$15</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                            </Col>
                           
                           <Col md={6}>
                           <div
                              className={
                                amount === "50"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="50"
                                      checked={amount === "50"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$50</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                           </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                            <div
                              className={
                                amount === "100"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="100"
                                      checked={amount === "100"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$100</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                            </Col>

                            <Col md={6}>

                            
                            <div
                              className={
                                amount === "200"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="200"
                                      checked={amount === "200"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$200</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                            <div
                              className={
                                amount === "500"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="500"
                                      checked={amount === "500"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$500</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                            </Col>

                            <Col md={6}>

                            <div
                              className={
                                amount === "1000"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="1000"
                                      checked={amount === "1000"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$1000</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                             </Col>
                          </Row>
                          {/* <Col md={6}>
                            <div
                              className={
                                amount === "15"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="15"
                                      checked={amount === "15"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$15</span>
                                  </div>
                                </div>
                              </label>
                            </div>

                            <div
                              className={
                                amount === "100"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="100"
                                      checked={amount === "100"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$100</span>
                                  </div>
                                </div>
                              </label>
                            </div>

                            <div
                              className={
                                amount === "500"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="500"
                                      checked={amount === "500"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$500</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div
                              className={
                                amount === "50"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="50"
                                      checked={amount === "50"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$50</span>
                                  </div>
                                </div>
                              </label>
                            </div>

                            <div
                              className={
                                amount === "200"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="200"
                                      checked={amount === "200"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$200</span>
                                  </div>
                                </div>
                              </label>
                            </div>

                            <div
                              className={
                                amount === "1000"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="1000"
                                      checked={amount === "1000"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$1000</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </Col> */}
                        </div>

                        {/* <div className={
                        amount === "15" ? style.selectedLabel :style.donateinputcontainer
                      }>
                    <label
                
                    >
                      <div className={style.innerlabel}>
                      <div className={style.smallscreeninput}>
                        <input
                          type="radio"
                          name="amount"
                          value="15"
                          checked={amount === "15"}
                          onChange={handleAmountChange}
                        />
                    
                        <span>$15</span>
                        </div>
                        <div>
                        <p>
                          {" "}
                          Covers 80% of one hour of a case worker’s salary to
                          assess a client’s needs.
                        
                        </p>
                        </div>
                     
                      </div>
                    </label>
                  </div>

                  <div className={amount === "25" ? style.selectedLabel : style.donateinputcontainer}>
                    <label
                    >
                      <div className={style.innerlabel}>
                      <div className={style.smallscreeninput}>
                        <input
                          type="radio"
                          name="amount"
                          value="25"
                          checked={amount === "25"}
                          onChange={handleAmountChange}
                        />
                   
                        <span>$25</span>
                        </div>
                        <div>
                        <p>
                          {" "}
                          Helps cover childbirth and parenting classes,
                          relationships training, material needs.
                        </p>
                        </div>
                      </div>
                    </label>
                  </div>

                  <div  className={amount === "50" ? style.selectedLabel : style.donateinputcontainer}>
                    <label
                    >
                      <div className={style.innerlabel}>
                      <div className={style.smallscreeninput}>
                        <input
                          type="radio"
                          name="amount"
                          value="50"
                          checked={amount === "50"}
                          onChange={handleAmountChange}
                        />
                     
                        <span>$50</span>
                        </div>
                      
                        <p>
                          {" "}
                          Covers the cost of onsite medical ultrasound
                          sonographer’s salary to verify pregnancy and prenatal
                          counseling.
                        </p>
                     
                      </div>
                    </label>
                  </div> */}

                        <Form.Group
                          className="my-3"
                          controlId="formBasicCheckbox">
                          <Form.Check
                            className={style.donationcomment}
                            type="checkbox"
                            label="Write us a comment"
                            aria-label="usercomment"
                            value={usercomment}
                            onClick={() => setShowTextArea(!showTextArea)}
                          />
                          {showTextArea && (
                            <textarea
                              className={`${style.commentBox}`}
                              type="textarea"
                              placeholder="Your Comment"
                              aria-label="usercomment"
                              value={usercomment}
                              onChange={(e) => setUserComment(e.target.value)} // Update comment state
                            />
                          )}
                        </Form.Group>
                        {/* <div className="d-flex justify-content-between my-3 ">
                      <div className={`${style.imgText} my-auto`}>
                        Free with donation of any amount
                      </div>
                      <div>
                        <img src="/donate/donateimg.svg" alt="donate" />
                      </div>
                    </div> */}
                        <div className={style.amountdonation}>
                          {/* <a href="#">Enter a custom donation amount</a> */}
                          <div className={style.labellink}>
                            <a href="#" onClick={handleLinkClick}>
                              Enter a custom donation amount
                            </a>
                          </div>
                          {showInput && (
                            <div className={style.customAmountInput}>
                              <input
                                type="text"
                                value={amount}
                                placeholder="Enter custom amount"
                                onChange={(e) =>
                                  setSelectedAmount(e.target.value)
                                }
                              />
                            </div>
                          )}
                          <div className={style.labellink}>
                            <a href="#" onClick={handleLinkClick}>
                              Enter Email
                            </a>
                          </div>
                         
                            <div className={style.customAmountInput}>
                              <input
                                type="email"
                                placeholder="Email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={style.informationinput}
                                disabled={isLoggedIn} // Disable if logged in
                                
                              />
                            </div>
                       
                        </div>

                        <h3>Choose a donation frequency</h3>
                        <div className={style.donateradio}>
                          <div
                            className={
                              selectedFrequency === "1"
                                ? style.selectedLabel2
                                : style.labeldonate
                            }>
                            <div className={style.donationlabel}>
                              <label className={style.labelcontent}>
                                <input
                                  type="radio"
                                  name="frequency"
                                  value="1"
                                  checked={selectedFrequency === "1"}
                                  onChange={handleFrequencyChange}
                                />

                                <span>OneTime</span>
                              </label>
                            </div>
                          </div>

                          <div
                            className={
                              selectedFrequency === "month"
                                ? style.selectedLabel2
                                : style.labeldonate2
                            }>
                            <div className={style.donationlabel}>
                              <label className={style.labelcontent}>
                                <input
                                  type="radio"
                                  name="frequency"
                                  value="month"
                                  checked={selectedFrequency === "month"}
                                  onChange={handleFrequencyChange}
                                />

                                <span>Monthly</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className={style.donationbuttoncontainer}>
                          {/* <div className={style.smallscreena}>
                    <a href="#">cancel</a>
                    </div> */}
                          <div className={style.smallscreenabutton}>
                            <button onClick={handleDonate}>
                              Go to Checkout
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="p-3 d-flex justify-content-between align-items-cente">
                <div>
                  <IoMdShare style={{ color: "#06A1D7" }} />{" "}
                  <span className="ms-2" style={{ fontSize: "20px" }}>
                    Share
                  </span>
                </div>
                <div className="  d-flex align-items-cente">
                  <Button
                    className="authBlubtn me-3"
                    variant="primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    size="sm"
                  >
                    <FaFacebookF /> Facebook
                  </Button>
                  <Button
                    className=" authBlubtn me-3"
                    variant="primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    size="sm"
                  >
                    <FaTwitter /> Twitter
                  </Button>
                  <Button
                    className="authBlubtn"
                    variant="primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    size="sm"
                  >
                    <FaLinkedinIn /> LinkedIn
                  </Button>
                </div>
              </div> */}
                </Col>
              )}
            </Row>
          </div>
        </div>
      </div>
      <VideoPlayer
        showVideoPlayer={showVideoPlayer}
        setShowVideoPlayer={setShowVideoPlayer}
        videoUrl={videoUrl}
        isdata={isdata}
      />
      <TransactionStatus
        showTransactionStatus={showTransactionStatus}
        setShowTransactionStaus={setShowTransactionStaus}
      />
    </div>
  );
};

export default Donation;
