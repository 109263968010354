import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl, getHeader } from "../utils/api";

export const getPaymnetDetails = () => async () => {
  try {
    const header = await getHeader();
    const response = await axios.get(`${baseUrl}/payment/details`, {
      headers: header,
    });
    console.log("get payment api response", response);
    if (response?.status === 200 || response?.status === 201) {
      //   dispatch(registerUserAction(response?.data?.user))
      return response?.data?.user;
    }
  } catch (error) {
    console.log("Error:", error);
    if (error?.response?.data) {
      const errorMessage = error.response.data.message;
      console.log("getUserData error:", errorMessage);
      toast.error(errorMessage);
    }
  }
};

export const oneTimePayment =
  ({ amount, centerId,usercomment,_id,email }) =>
  async () => {
    try {
      const header = await getHeader();
      const response = await axios.post(
        `${baseUrl}/payment/one-time`,
        {
          amount,
          centerId,
          usercomment,
          _id,
          email
        },
        {
          headers: header,
        }
      );
      if (response?.status === 200 || response?.status === 201) {
        return response;
      }
    } catch (error) {
      console.log("Error:", error);
      if (error?.response?.data) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage)
        console.log("one time payment error:", errorMessage);
      }
    }
  };

  export const AdminPayment =
  ({ amount,email,id }) =>
  async () => {
    try {
      const header = await getHeader();
      const response = await axios.post(
        `${baseUrl}/payment/admin-one-time`,
        {
          amount,
          email,id
        },
        {
          headers: header,
        }
      );
      if (response?.status === 200 || response?.status === 201) {
        return response;
      }
    } catch (error) {
      console.log("Error:", error);
      if (error?.response?.data) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage)
        console.log("one time payment error:", errorMessage);
      }
    }
  };

 

export const createSubscription =
  ({ amount, interval, centerId,usercomment,_id,email }) =>
  async () => {
    try {
      const header = await getHeader();
      const response = await axios.post(
        `${baseUrl}/payment/subscription`,
        {
          amount,
          centerId,
          interval,
          usercomment,
          _id,
          email
        },
        {
          headers: header,
        }
      );
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      }
    } catch (error) {
      console.log("Error:", error);
      if (error?.response?.data) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage)
        console.log("create subscription error:", errorMessage);
      }
    }
  };


  export const createAdminSubscription =
  ({ amount, interval, email,id }) =>
  async () => {
    try {
      const header = await getHeader();
      const response = await axios.post(
        `${baseUrl}/payment/adminsubscription`,
        {
          amount,
          email,
          interval,
          id
         
        },
        {
          headers: header,
        }
      );
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      }
    } catch (error) {
      console.log("Error:", error);
      if (error?.response?.data) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage)
        console.log("create subscription error:", errorMessage);
      }
    }
  };


  export const getRecentTransaction = ({id}) => async () => {
    try {
      const response = await axios.get(`${baseUrl}/admin/recent-transactions/${id}`,);
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      }
    } catch (error) {
      console.log("Error:", error);
      if (error?.response?.data) {
        const errorMessage = error.response.data.message;
        console.log("get center error:", errorMessage);
      }
    }
  };

  

  export const getSubscriptions = ({id}) => async () => {
    try {
      const header = await getHeader();
      const response = await axios.get(`${baseUrl}/users/subscriptions/${id}`,
        {
          headers: header,
        }

      );
      if (response?.status === 200 || response?.status === 201) {
        return response?.data?.result;
      }
    } catch (error) {
      console.log("Error:", error);
      if (error?.response?.data) {
        const errorMessage = error.response.data.message;
        console.log("get center error:", errorMessage);
      }
    }
  };

  export const cancelSubscriptions = ({id}) => async () => {
    try {
      const header = await getHeader();
      const response = await axios.delete(`${baseUrl}/payment/cancel-subscription/${id}`,
        {
          headers: header,
        }

      );
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      }
    } catch (error) {
      console.log("Error:", error);
      if (error?.response?.data) {
        const errorMessage = error.response.data.message;
        console.log("get center error:", errorMessage);
      }
    }
  };

  export const getTotalTransactionandPercentage =
  ({ id }) =>
  async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/admin/transaction/amount/${id}`,
      );

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      }
    } catch (error) {
      console.error('Error:', error);
      if (error?.response?.data) {
        const errorMessage = error.response.data.message;
        console.log(' get all transaction center error:', errorMessage);
        //    toast.error(errorMessage)
      }
    }
  };
  export const fetchConnectedAccount = async ({id}) => {
    try {
   console.log("Enter")
        const header = await getHeader();
      const response =  await axios.get(
        `${baseUrl}/admin/getconnect/${id}`,
        { headers: header }
      
      );
      console.log("REsponse",response)
        
      return response.data;
    } catch (error) {
      console.error('Error fetching connected account:', error);
      throw error;
    }
  };
